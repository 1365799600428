<template>
  <div class="page">
    <!--查询框-->
    <div class="search-box section page-header" style="margin-bottom: 1px;">
      <el-form ref="searchForm" :inline="true" :model="query" size="medium">
        <el-form-item prop="parkingLotId">
          <!--          <SuggestParking v-model="query.parkingLotId" />-->
          <el-input
              v-model="query.inspectorName"
              placeholder="请输入巡检员姓名"
              prefix-icon="el-icon-search"
              class="width260 margin-right10 margin-left38"
          />
        </el-form-item>
        <el-form-item prop="timeRange">
          <el-date-picker
              v-model="query.timeValue"
              end-placeholder="结束日期"
              prefix-icon="none"
              range-separator="~"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ summaryData.total }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
<!--      v-loading="isLoading"-->
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column
            align="center"
            label="巡检员姓名"
            prop="inspectorName"
            width="260"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="停车场"
            prop="parkingLotName"
        >
        </el-table-column>
          <el-table-column
              align="center"
              label="打印数量"
              prop="ticketCount"
              width="200"
          >
          </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import SuggestParking from "@/components/SuggestParking";
// import Settlement from "@/components/Settlement";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import {
  // changePassword,
  // forcedSignOut,
  // inspectorList,
  // departInspector,
  // addInspector,
  // editInspector,
  // inspectorDetail,
  getTicketingTotalRecords,
} from "@/api/patrolInspection";
import {findParkingBySettlementId} from "@/api/settlementManage";
// import Picture from "@/components/Picture";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";
// import Numplate from "@/components/Numplate.vue";
// import {selectCurledHailLog} from "@/api/user";

export default {
  name: "people_manage",
  mixins: [BaseMixin, Permission],
  components: {
    // Numplate
    // SuggestParking,
    // Settlement,
    // Picture,
  },
  data() {
    return {
      summaryData:{},
      isEdit: false, //判断是否来自编辑，第一次不清空车场
      query: {
        timeValue: [],
        inspectorName: "",
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      dataList: [],
      workStatus: {
        0: "在职",
        1: "离职",
      },
      inspectorDialogVisible: false, //添加编辑巡检员弹窗
      isInspectorLoading: false, //提交添加编辑loading
      title: "",
      options: [], //车场列表选项
      model: {
        id: "",
        // certificateNumber: "",
        settlementId: "",
        parkingLotIds: "",
        inspectorName: "",
        phone: "",
        sex: "1",
        email: "",
        avatar: "",
      },
      rules: {
        // settlementId: [{required: true, message: "请选择结算方"}],
        // parkingLotIds: [{required: true, message: "请选择停车场"}],
        inspectorName: [{required: true, message: "姓名不能为空"}],
        phone: [{required: true, message: "手机号不能为空"}],
        email: [{required: true, message: "邮箱不能为空"}],
        // certificateNumber: [{required: true, message: "身份证号不能为空"}],
        sex: [{required: true, message: "请选择性别"}],
        avatar: [{required: true, message: "请选择头像"}],
      },
      changePasswordDialogVisible: false, //修改密码弹窗
      isChangeLoading: false, //提交修改密码loading
      changePasswordId: "",
      changeData: {
        newPassword: "",
      },
      rulesChangeData: {
        newPassword: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("密码不能为空"));
              } else if (
                  !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/.test(value)
              ) {
                callback(
                    new Error(
                        "必须包含大小写字母和数字的组合,可以使用特殊字符,长度在8-16之间"
                    )
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  //监听选中的结算方，查询当前结算方关联车场
  watch: {
    "model.settlementId"(newVal) {
      if (this.isEdit !== true) {
        this.model.parkingLotIds = "";
      }
      const pageSize = 9999;
      const pageNo = 1;
      const id = newVal;
      findParkingBySettlementId(id, pageSize, pageNo).then((res) => {
        this.isEdit = false;
        if (res && res.code === 30 && res.result) {
          this.options = res.returnObject.list;
        }
      });
    },
  },
  methods: {
    //监听选中的结算方，查询当前结算方关联车场
    // getParkingLot() {
    //   this.model.parkingLotIds = "";
    //   const pageSize = 9999;
    //   const pageNo = 1;
    //   const id = this.model.settlementId;
    //   findParkingBySettlementId(id, pageSize, pageNo).then((res) => {
    //     if (res && res.code === 30 && res.result) {
    //       this.options = res.returnObject.list;
    //     }
    //   });
    // },
    //重置查询条件
    resetQueryForm() {
      this.query = {
        timeValue: [],
        inspectorName: "",
      };
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      checkToken()
          .then(async res => {
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
              MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                confirmButtonText: '重新登录',
                callback: () => {
                  window.localStorage.clear()
                  window.$vue.$router.push('/login')
                }
              });
            } else {
              this.isLoading = true;
              const params = this.paramFormat(this.query);
              if (this.query.timeValue.length > 0) {
                params["startDate"] = this.query.timeValue[0] + " 00:00:00";
                params["endDate"] = this.query.timeValue[1] + " 23:59:59";
              }
              const res = await getTicketingTotalRecords(params);
              this.isLoading = false;
              if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                this.summaryData = res.returnObject;
                this.dataList = returnObject.records;
                this.pagination.totalSize = returnObject.total;
                this.pagination.totalPages = returnObject.pages;
                this.form.exportSize = returnObject.total;
              }
            }
          })
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.avatar-uploader .el-upload:hover {
  border-color: #0768FD;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-form-item--medium .el-form-item__label{
  width: 100px;
}
</style>
